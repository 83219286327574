import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Button,
  Modal,
  Checkbox,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { decryptData } from "../../crypto";
import {
  fetchExamLanguages,
  fetchQuestionsData,
  fetchProfileApi,
  fetchCurrentTimeApi,
} from "./api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { examStartApi } from "./api";
import ProfileDrawer from "./profileDrawer";
import ProfileSection from "./profileSection";
import socket from "./socket";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",

    "& .MuiTypography-h4": {
      color: "#FFF",
      background: "#4565B7",
      textAlign: "center",
      fontFamily: "Playfair Display",
      fontSize: "30px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "2rem",
      textTransform: "capitalize",
    },
    "& .MuiTypography-body1": {
      color: "#000",
      fontFamily: "Satoshi",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: "600",
      textTransform: "capitalize",
    },
    "& .MuiTypography-h6": {
      color: "#000",
      fontFamily: "Satoshi",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      textTransform: "capitalize",
    },
  },
  mobilesection: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  section: {
    width: " 18%",
    padding: "1rem",
    background: "#FFF",
    boxShadow: "0px 8px 15px #D7DAF2",
    zIndex: "1200",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  Instructionsection: {
    width: "100%",
    height: "100vh",
    background: "#FFF",
    filter: "drop-shadow(0px 8px 20px #D7DAF2)",
  },
  dflex: {
    display: "flex",
    alignItems: "center",
  },
  mt1: {
    marginTop: "1rem",
  },
  ml1: {
    marginLeft: "0.5rem",
    TextTrack: "wrap",
  },
  profileImagecontainer: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    background: "#E0E0E2",
    borderRadius: "10px",
  },
  profileImage: {
    maxWidth: "100%",
    aspectRatio: "1 / 1",
    borderRadius: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
  },
  popuptext: {
    display: "flex",
    alignItems: "flex-start",
    "& .MuiTypography-h6": {
      color: "#000",
      fontFamily: "'Jost', sans-serif",
      fontSize: "0.9375rem",
      fontStyle: "normal",
      fontWeight: "400",
      textAlign: "start",
      lineHeight: "1.25rem",
      textTransform: "capitalize",
    },
  },
  rednote: {
    color: "red",
    padding: "0 1rem 0.5rem 1rem",
    fontFamily: "'Playfair Display', serif !important",
    fontSize: "0.9rem",
    fontStyle: "normal",
    fontWeight: "500",
    textAlign: "start",
    lineHeight: "1.25rem",
    textTransform: "capitalize",
  },
  buttonContainer: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 32px 8.5px 14px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  buttonContainerpopup: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 32px 8.5px 14px",
    },
  },
  buttonContainermobile: {
    margin: "1rem",
    display: "none",
    justifyContent: "flex-end",
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 32px 8.5px 14px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  mt6: {
    marginTop: "6rem",
  },
  langoption: {
    marginRight: "1rem",
  },
  Instructionmain: {
    height: "100vh",
    display: "flex",
    width: "81%",
    alignContent: "space-around",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  Instructioncontent: {
    // height: "86vh",
    // padding: "1rem",
    // overflow: "scroll",
    // "&::-webkit-scrollbar ": {
    //   display: "none",
    // },
    margin: "20px",
    height: "85%",
    overflow: "auto",
    [theme.breakpoints.down("xs")]: {
      height: "75%",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "& img": {
        width: "100%",
      },
    },
    "&::-webkit-scrollbar": {
      width: "20px",
    },

    /* Track */
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#4565B7",
      borderRadius: "10px",
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#39476B",
    },
  },
  Instructionplusbutton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFF",
    background: "#4565B7",
    textAlign: "center",
    fontFamily: "Playfair Display",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: "700",
    textTransform: "capitalize",
    width: "100%",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    "& .MuiOutlinedInput-root": {
      background: "white",
      margin: "0.5rem",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 30px 10.5px 14px",
    },
  },
  languagecontrol: {
    width: "142px",
    height: "40px",
    borderRadius: "4px",
    border: "1px solid #000",
    background: "#FFF",
    marginTop: ".4rem",
  },
}));

const Instructions = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // Profile state
  const [profilePic, setProfilePic] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [examInstructions, setExamInstructions] = useState("");
  const [examInstructionsHindi, setExamInstructionsHindi] = useState("");
  const [exam_name, setExamName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [rollNumber, setRollNumber] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("english");
  const [languages, setLanguages] = useState([]);
  const [examDuration, setExamDuration] = useState("");
  const [startDateTime, setStartDateTime] = useState(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [showSubmissionPopupReLogin, setShowSubmissionPopupReLogin] = useState(false);
  const [countdownByHead, setCountdownByHead] = useState(0);

  const fetchQuestions = async (examId) => {
    try {
      const questionsData = await fetchQuestionsData(examId);

      const storedExamDuration = questionsData.duration;
      setExamDuration(storedExamDuration);
      sessionStorage.setItem('examDuration',convertMinutesToTimeFormat(Number(storedExamDuration)))
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  function convertMinutesToTimeFormat(totalMinutes) {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = 0;

    // Format with leading zeros
    let formattedTime = 
        String(hours).padStart(2, '0') + ":" + 
        String(minutes).padStart(2, '0') + ":" + 
        String(seconds).padStart(2, '0');

    return formattedTime;
}

  useEffect(() => {
    const examId = sessionStorage.getItem("examId");
    handlefetchExamLanguages(examId);
  }, []);


  useEffect(() => {
    socket.connect();
    const user = sessionStorage.getItem("userId");
    const exam = sessionStorage.getItem("examId");
   
    const handleReLogin = ({examId, userId}) =>{
      if(userId===user&&examId===exam){
       setShowSubmissionPopupReLogin(true)
       let timer = 5;
       const countdownInterval = setInterval(() => {
         setCountdownByHead(timer);
         if (timer === 1) {
          sessionStorage.clear();
          navigate("/")
           clearInterval(countdownInterval);
           setShowSubmissionPopupReLogin(false);
         }
         timer--;
       }, 1000);
      }
    }

    const handleReLoginAll = ({examId}) =>{
      if(examId===exam){
       setShowSubmissionPopupReLogin(true)
       let timer = 5;
       const countdownInterval = setInterval(() => {
         setCountdownByHead(timer);
         if (timer === 1) {
          sessionStorage.clear();
          navigate("/")
           clearInterval(countdownInterval);
           setShowSubmissionPopupReLogin(false);
         }
         timer--;
       }, 1000);
      }
    }

    const selectedLogout = ({ learnerIds }) => {
      if (learnerIds.map(String).includes(user)) {
        console.log("learnerIds",learnerIds)
        setShowSubmissionPopupReLogin(true);
        let timer = 5;
        const countdownInterval = setInterval(() => {
          setCountdownByHead(timer);
          if (timer === 1) {
            sessionStorage.clear();
            navigate("/");
            clearInterval(countdownInterval);
            setShowSubmissionPopupReLogin(false);
          }
          timer--;
        }, 1000);
      }
    };


  
    socket.on("re_login",handleReLogin)
    socket.on("re_login_all",handleReLoginAll)
    socket.on("selected_logout", selectedLogout);
    

    return () => {
      socket.off("re_login", handleReLogin);
      socket.off("re_login_all",handleReLoginAll)
      socket.off("selected_logout", selectedLogout);
      
      socket.disconnect()
    };
  }, []);

  const handleChangeLanguage = (event) => {
    setSelectLanguage(event.target.value);
  };

  const handlefetchExamLanguages = async (examId) => {
    try {
      const languageData = await fetchExamLanguages(examId);
      if (languageData && languageData.length > 0) {
        setLanguages(
          languageData.map((lang) => ({
            label:
              lang.language.charAt(0).toUpperCase() + lang.language.slice(1),
            value: lang.language,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  useEffect(() => {
    const examId = sessionStorage.getItem("examId");
    handlefetchExamLanguages(examId);
  }, []);

  useEffect(() => {
    const examId = sessionStorage.getItem("examId");
    if (examId) {
      fetchQuestions(examId);
    }
  }, []);

  // Modal state
  const [modalOpen, setModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Retrieve data from sessionStorage
  useEffect(() => {
    setExamInstructions(
      decryptData(sessionStorage.getItem("examInstructions"))
    );
    setExamInstructionsHindi(
      decryptData(sessionStorage.getItem("instructionsHindi"))
    );
    setExamName(decryptData(sessionStorage.getItem("exam_name")));
    const examTime = new Date(sessionStorage.getItem("startDateTime"));
    setStartDateTime(examTime);

    //exam start toast
    if (examTime) {
      const currentTime = new Date();
      if (currentTime < examTime) {
        const timeout = setTimeout(() => {
          toast.success("The exam is now live. You can start!", {
            toastId: "examTime",
          });
          clearTimeout(timeout);
        }, examTime - currentTime);
      }
    }
  }, []);

  useEffect(() => {
    if (selectLanguage === "hindi") {
      setExamInstructions(examInstructionsHindi);
    } else {
      setExamInstructions(
        decryptData(sessionStorage.getItem("examInstructions"))
      );
    }
  }, [selectLanguage, examInstructionsHindi]);

  // user profile api
  const fetchProfileData = async () => {
    try {
      const candidateData = await fetchProfileApi();
      sessionStorage.setItem("userId",candidateData.id)
      setProfilePic(candidateData.image_url);
      setName(candidateData.full_name);
      setEmail(candidateData.email);
  setMobileNumber(candidateData.phone)
    setRollNumber(candidateData.learner_information.roll_number)
      // Convert the incoming DOB format to "7 Dec 2003" format
      const rawDob = candidateData.learner_information.dob;
      const dobDate = new Date(rawDob);

      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const day = dobDate.getDate();
      const monthIndex = dobDate.getMonth();
      const year = dobDate.getFullYear();

      const formattedDob = `${day} ${monthNames[monthIndex]} ${year}`;

      setDob(formattedDob);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleOpenModal = async() => {
    const response = await fetchCurrentTimeApi();

const formatedcurrentTime = new Date(response.data.currentTime)

    if (startDateTime.getTime() <= formatedcurrentTime.getTime()) {
      if (sessionStorage.getItem("isChecked")) {
        handleNextButtonClick();
      } else {
        setModalOpen(true);
      }
    } else {
      toast.warning("You cannot start the exam before schedule.");
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const [remainingTime, setRemainingTime] = useState("03:00:00");

  const handleNextButtonClick = () => {
    if (sessionStorage.getItem("isChecked")) {
      navigate("/test", { state: { selectLanguage: selectLanguage } });
    } else if (isChecked) {
      const examId = sessionStorage.getItem("examId");

      fetchExamStartApi(examId);

      navigate("/test", { state: { selectLanguage: selectLanguage } });
      sessionStorage.setItem("loginTime", new Date().getTime());
      sessionStorage.setItem("examStarted", true);
      sessionStorage.setItem("remainingTime", remainingTime);
      sessionStorage.setItem("isChecked", isChecked);
      // sessionStorage.setItem("languageSelected", selectLanguage);
      sessionStorage.removeItem("candidateLoggedinToInstructionsPage");
    } else {
      // alert("Please confirm that you have read the instructions.");
      toast.warning("Please confirm that you have read the instructions!");
    }
  };

  const fetchExamStartApi = async (examId) => {
    try {
      // Fetching data from the examStartApi
      const responseData = await examStartApi(examId);
 
      if (responseData) {
        const examDurationInSeconds = responseData.time_left

        setRemainingTime(examDurationInSeconds);
        sessionStorage.setItem("remainingTime", examDurationInSeconds);

  
      }
    } catch (error) {
      console.error("Error fetching start time of the exam:", error);
    }
  };

  const instructionTexts = {
    english:
      "I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that no prohibited gadget like mobile phone, Bluetooth devices etc., or any prohibited material is with me in the Examination Hall. I agree that if found guilty of any malpractice, I may be debarred from this Test and/or subjected to disciplinary action, which may include a ban from future Tests/Examinations.",
    hindi:
      "मैंने निर्देश पढ़े और समझे हैं। मुझे आवंटित सभी कंप्यूटर हार्डवेयर ठीक काम कर रहे हैं। मैं घोषित करता हूं कि परीक्षा हॉल में मेरे पास कोई प्रतिबंधित गैजेट जैसे मोबाइल फोन, ब्लूटूथ उपकरण आदि या कोई भी प्रतिबंधित सामग्री नहीं है। मैं सहमत हूं कि यदि किसी भी अनुचित प्रक्रिया का दोषी पाया जाता है, तो मुझे इस परीक्षण से वंचित किया जा सकता है और/या अनुशासनात्मक कार्रवाई के अधीन किया जा सकता है, जिसमें भविष्य के परीक्षणों/परीक्षाओं से प्रतिबंध शामिल हो सकता है।",
  };
  const [instructionText, setInstructionText] = useState(
    instructionTexts.english
  );

  useEffect(() => {
    setInstructionText(instructionTexts[selectLanguage]);
  }, [selectLanguage]);

  return (
    <>
     {showSubmissionPopupReLogin && (
                <Dialog open={showSubmissionPopupReLogin}>
                  <DialogTitle>
                  Logout by the head authority, please login again
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <div className={classes.p1}>
                        Navigating to the login page in {countdownByHead} seconds...
                      </div>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              )}

      <ToastContainer />
      <div className={classes.container}>
        {/* Profile Section */}
        <div className={classes.section}>
          <ProfileSection
            exam_name={exam_name}
            profilePic={profilePic}
            examDuration={examDuration}
            dob={dob}
            email={email}
            name={name}
            rollNumber={rollNumber}
            mobileNumber={mobileNumber}
            closestatus="false"
          />
        </div>
        {/* Instructions Section */}
        <div className={classes.Instructionmain}>
          <div className={classes.Instructionsection}>
            <div className={classes.Instructionplusbutton}>
              <div>
                <div className={classes.mobilesection}>
                  <ProfileDrawer
                    exam_name={exam_name}
                    profilePic={profilePic}
                    examDuration={examDuration}
                    dob={dob}
                    email={email}
                    name={name}
                    rollNumber={rollNumber}
                    mobileNumber={mobileNumber}
                  />
                </div>
              </div>
              <Typography variant="h4">Instructions</Typography>
              <Select
                className={classes.langoption}
                variant="outlined"
                onChange={handleChangeLanguage}
                value={selectLanguage}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Select language</em>
                </MenuItem>
                {languages.map((lang) => (
                  <MenuItem key={lang.value} value={lang.value}>
                    {lang.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={classes.Instructioncontent}>
              <div dangerouslySetInnerHTML={{ __html: examInstructions }} />
              <div className={`${classes.buttonContainer} ${classes.mt6}`}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenModal}
                >
                  Next
                </Button>
              </div>
            </div>
            <div className={`${classes.buttonContainermobile}`}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
              >
                Next
              </Button>
            </div>
          </div>

          {/* Modal */}
          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            className={classes.modal}
          >
            <div className={classes.modalContent}>
              <div>
                <Typography variant="h5" className={classes.rednote}>
                  Please note all questions will appear in your default
                  language. This language can be changed for a particular
                  question later on.
                </Typography>
                <div className={classes.popuptext}>
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                  />
                  <Typography variant="h6">{instructionText}</Typography>
                </div>
              </div>

              <div className={classes.buttonContainerpopup}>
                <div>
                  <Select
                    className={classes.langoption}
                    variant="outlined"
                    onChange={handleChangeLanguage}
                    value={selectLanguage}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em>Select language</em>
                    </MenuItem>
                    {languages.map((lang) => (
                      <MenuItem key={lang.value} value={lang.value}>
                        {lang.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNextButtonClick}
                >
                  Next
                </Button>
              </div>
            </div>
          </Modal>

          {/* Next Button */}
        </div>
      </div>
    </>
  );
};

export default Instructions;
